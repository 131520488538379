import { Injectable, isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  // Log informational messages
  log(message: string, ...optionalParams: any[]): void {
    if (isDevMode()) {
      console.log(`[LOG]: ${message}`, ...optionalParams);
    }
  }

  // Log warnings
  warn(message: string, ...optionalParams: any[]): void {
    if (isDevMode()) {
      console.warn(`[WARN]: ${message}`, ...optionalParams);
    }
  }

  // Log errors
  error(message: string, ...optionalParams: any[]): void {
    if (isDevMode()) {
      console.error(`[ERROR]: ${message}`, ...optionalParams);
    }
  }

  // Log debug information
  debug(message: string, ...optionalParams: any[]): void {
    if (isDevMode()) {
      console.debug(`[DEBUG]: ${message}`, ...optionalParams);
    }
  }
}
