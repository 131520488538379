<div
  class="sidebar"
  [class.expanded]="isExpanded"
  (mouseover)="toggleSidebar(true)"
  (mouseout)="toggleSidebar(false)"
  (focus)="toggleSidebar(true)"
  (blur)="toggleSidebar(false)"
  tabindex="0"
>
  <div class="sidebar-wrapper">
    <a href="/order" class="logo">
      <img src="/assets/logo-white.svg" alt="Logo" />
    </a>
    <hr />
    <div class="menu-items">
      <a href="/order" class="menu-item">
        <div class="icon"><img src="/assets/order.svg" alt="Aufträge" /></div>

        <span class="menu-text" *ngIf="isExpanded">Aufträge</span>
      </a>
    </div>
    <div class="user-login" *ngIf="isExpanded">
      <div class="icon">
        <img class="profil-icon" src="/assets/profil.svg" alt="Profil" />
      </div>
      <div class="user-info">
        <span class="user-name">{{ name }}</span>
      </div>
      <button
        (click)="logoutUser()"
        (keyup.enter)="logoutUser()"
        (keyup.space)="logoutUser()"
        tabindex="0"
        class="logout-button"
      >
        <img src="/assets/logout-white.svg" alt="Logout" />Logout
      </button>
    </div>
    <div class="user-login-collapsed" *ngIf="!isExpanded">
      <div class="menu-item">
        <div class="icon">
          <img src="/assets/logout-white.svg" alt="Logout" />
        </div>
      </div>
    </div>
  </div>
</div>
