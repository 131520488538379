<div class="order-headline">
  <h2>Aufträge</h2>
  <div class="buttons">
    <app-button
      (click)="downloadFile()"
      type="secondary-icon-button"
      icon="/assets/download-dark-blue.svg"
      label="Auftragsvorlage"
    />
    <app-file-upload (fileUploadSuccessEvent)="updateAllOrders()" />
  </div>
</div>
<app-filter
  (filterListEvent)="filterAllOrders($event)"
  (httpParams)="(filterValues)"
/>
@if (isLoading) {
  <app-loading-animation />
}
@if (!isLoading && error === null) {
  <app-table
    [orderList]="orderList"
    [totalPages]="totalPages"
    (paginationChangeEvent)="filterAllOrders($event.params)"
  />
}
@if (error) {
  <div class="error-container"><app-error [error]="error" /></div>
}
