import { Component, OnInit } from '@angular/core';

@Component({
  standalone: true,
  imports: [],
  template: 'Error',
})
export class DummyComponent implements OnInit {
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
}
