import { Component, OnInit, ViewChild } from '@angular/core';
import { DetailCardComponent } from '../../shared/components/detail-card/detail-card.component';
import { TableComponent } from '../../shared/components/table/table.component';
import { ChipComponent } from '../../shared/components/chip/chip.component';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../../core/order.service';

import { DetailTableComponent } from '../../shared/components/detail-table/detail-table.component';
import { ErrorComponent } from '../../shared/components/error/error.component';
import { LoadingAnimationComponent } from '../../shared/components/loading-animation/loading-animation.component';
import { finalize } from 'rxjs';
import { ButtonComponent } from '../../shared/components/button/button.component';

import { FileService } from '../../shared/components/file-upload/file.service';
import { LoggerService } from '../../core/logger.service';
import { TokenService } from '../../core/token.service';
import { UserRole } from '../../core/user-role.enum';
import { ToastService } from '../../core/toast.service';
import { OrderDetailResponse } from '../../shared/models/order/order-detail-response.model';
import { OrderStatus } from '../../shared/models/status/order-status.enum';
import { HygieneInspectionStatus } from '../../shared/models/status/hygiene-inspection-status.enum';
import { CommonModule } from '@angular/common';
import { AggregateAnalysisComponent } from '../../shared/components/aggregate-analysis/aggregate-analysis.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-order-detail',
  standalone: true,
  imports: [
    DetailCardComponent,
    TableComponent,
    ChipComponent,
    DialogComponent,
    DetailTableComponent,
    ErrorComponent,
    LoadingAnimationComponent,
    ButtonComponent,
    CommonModule,
    AggregateAnalysisComponent,
  ],
  templateUrl: './order-detail.component.html',
  styleUrl: './order-detail.component.scss',
})
export class OrderDetailComponent implements OnInit {
  @ViewChild(DialogComponent) dialogComponent!: DialogComponent;
  orderDetail!: OrderDetailResponse;
  idStr = '';
  isLoading = true;
  error: Error | null = null;
  isShowingAggregateAnalysis = false;
  isDownloading = false;
  showDeleteDialog = false;
  OrderStatus = OrderStatus;
  UserRole = UserRole;
  HygieneInspectonStatus = HygieneInspectionStatus;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private fileService: FileService,
    private loggerService: LoggerService,
    private toastService: ToastService,
    private tokenService: TokenService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.idStr = params.get('id')!;
      this.titleService.setTitle(`Auftrag ${this.idStr} | IDR Backoffice`);
      this.getOrderById(this.idStr);
    });
  }

  private getOrderById(id: string) {
    this.orderService
      .getOrderDetail(id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (data) => {
          this.loggerService.log('Fetch order details completed');
          this.orderDetail = data;
          this.isLoading = false;
        },
        error: (error: Error) => {
          this.loggerService.error('Error fetching order details');
          this.error = error;
        },
      });
  }

  hasUserRole(role: UserRole): boolean {
    return this.tokenService.hasRole(role);
  }

  openAggregateAnalysis() {
    this.isShowingAggregateAnalysis = !this.isShowingAggregateAnalysis;
  }

  getArrowImage(): string {
    return this.isShowingAggregateAnalysis
      ? '/assets/double-arrow-up.svg'
      : '/assets/double-arrow-down.svg';
  }

  setOrderStatus(status: OrderStatus) {
    this.orderService.updateOrderStatus(this.orderDetail.id, status).subscribe({
      next: (response) => {
        this.updateDetailOnChange();
        this.toastService.show('success', 'Status gesetzt', response.message);
      },
      error: () => {
        this.loggerService.error('Failed to update order status');
        this.toastService.show(
          'warn',
          'Status konnte nicht verändert werden',
          'Der Status des Auftrags konnte nicht auf Versendet geändert werden.'
        );
      },
    });
  }

  setInspectionStatus(status: HygieneInspectionStatus) {
    this.orderService
      .updateHygieneInspectionStatus(this.orderDetail.id, status)
      .subscribe({
        next: (response) => {
          this.updateDetailOnChange();
          this.toastService.show(
            'success',
            'Hygieneinspektionsstatus gesetzt',
            response.message
          );
        },
      });
  }

  generateArtifacts(orderId: string) {
    this.fileService.generateArtifacts(orderId).subscribe({
      next: (response) => {
        this.loggerService.log('Generate Artifacts started');
        this.toastService.show(
          'success',
          'Generate Artifacts started',
          response.message
        );
      },
    });
  }

  updateDetailOnChange() {
    this.getOrderById(this.idStr);
  }
  openDialog() {
    this.dialogComponent.showDialog();
  }

  toggleDeleteDialog() {
    this.showDeleteDialog = !this.showDeleteDialog;
  }

  deleteOrder(id: string) {
    this.orderService.deleteOrder(id).subscribe({
      next: (response) => {
        this.router.navigate(['/order']);
        this.toastService.show('success', 'Auftrag gelöscht', response.message);
      },
    });
  }

  downloadOrderReport(orderId: string): void {
    this.isDownloading = true;
    this.fileService.downloadOrderReport(orderId).subscribe({
      next: (response) => {
        this.handleFileDownload(response);
      },
    });
  }

  handleFileDownload(response: any): void {
    const contentDispositionHeader =
      response.headers.get('Content-Disposition') || '';

    const fileName = this.fileService.extractFilename(contentDispositionHeader);

    const blob = response.body;

    if (blob) {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(url);
    }
    this.isDownloading = false;
  }

  formatDate(inputDate: string): string {
    const date = new Date(inputDate);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }
}
