<input
  type="file"
  class="file-input"
  multiple="true"
  (change)="onFileSelected($event)"
  #fileUpload
  accept=".xls, .xlsx"
/>
<app-button
  (click)="fileUpload.click()"
  type="primary-icon-button"
  icon="/assets/upload.svg"
  label="Auftrag hochladen"
/>
