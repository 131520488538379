import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ChipComponent } from '../chip/chip.component';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OrderResponse } from '../../models/order/order-response.model';
import { PaginatorModule } from 'primeng/paginator';
import { QueryParamsService } from '../../../core/query-params.service';
import { HttpParams } from '@angular/common/http';
import { SortDirection } from './sort-direction.enum';
import { OrderStatus } from '../../models/status/order-status.enum';
import { UserRole } from '../../../core/user-role.enum';
import { TokenService } from '../../../core/token.service';

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    ChipComponent,
    CommonModule,
    FormsModule,
    RouterModule,
    PaginatorModule,
  ],
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges {
  @Input() orderList: OrderResponse[] = [];
  @Input() totalPages = 0;

  totalRecords = 0;

  @Output() paginationChangeEvent = new EventEmitter<{
    params: HttpParams;
  }>();

  columns = [
    { key: 'orderNumber', label: 'Auftr.nr.', allowSort: true, align: 'left' },
    {
      key: 'customerName',
      label: 'Auftraggeber',
      allowSort: true,
      align: 'left',
    },
    { key: 'locationName', label: 'Standort', allowSort: true, align: 'left' },
    { key: 'inspector', label: 'Prüfer', allowSort: false, align: 'left' },
    { key: 'startDate', label: 'Start', allowSort: true, align: 'left' },
    { key: 'endDate', label: 'Ende', allowSort: true, align: 'left' },
    { key: 'status', label: 'Auftr.status', allowSort: false, align: 'center' },
    {
      key: 'hygieneStatus',
      label: 'Hyg.status',
      allowSort: false,
      align: 'center',
    },
  ];

  sortedData: OrderResponse[] = this.orderList;
  itemsPerPageOptions = [5, 10, 25, 50, 100];
  itemsPerPageShown = this.itemsPerPageOptions[2];
  first = 0;

  currentPage = 1;
  sortColumn = '';
  sortDirection: SortDirection = SortDirection.NONE;
  SortDirection = SortDirection;
  OrderStatus = OrderStatus;
  UserRole = UserRole;

  constructor(
    private router: Router,
    private queryService: QueryParamsService,
    private tokenService: TokenService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const http = this.queryService.getCurrentQueryParams();

    this.updateSortParams(http);
    this.updatePaginationParams(http);
    this.handleOrderListChange(changes);
  }

  onPageChange(event: any) {
    this.first = event.first;
    this.currentPage = event.page;
    this.itemsPerPageShown = event.rows;

    let http: HttpParams = this.queryService.getCurrentQueryParams();

    http = http
      .set('page', this.currentPage)
      .set('size', this.itemsPerPageShown);

    this.paginationChangeEvent.emit({
      params: http,
    });
  }

  setSortingParams(sortKey: string, sortDirection: string): void {
    let currentHttpParams = this.queryService.getCurrentQueryParams();
    currentHttpParams =
      sortDirection !== SortDirection.NONE
        ? currentHttpParams
            .set('sortKey', sortKey)
            .set('sortDirection', sortDirection)
        : currentHttpParams.delete('sortKey').delete('sortDirection');
    this.paginationChangeEvent.emit({
      params: currentHttpParams,
    });
  }

  stopEventBubbling(event: MouseEvent): void {
    event.stopPropagation();
  }

  openDetailPage(orderNumber: string): void {
    this.router.navigate(['/order', orderNumber]);
  }

  setSort(column: string): void {
    if (this.sortColumn === column) {
      this.sortDirection =
        this.sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : this.sortDirection === SortDirection.DESC
            ? SortDirection.NONE
            : SortDirection.ASC;
      if (this.sortDirection === SortDirection.NONE) {
        this.sortColumn = '';
      }
    } else {
      this.sortColumn = column;
      this.sortDirection = SortDirection.ASC;
    }

    this.setSortingParams(this.sortColumn, this.sortDirection);
  }

  formatDate(inputDate: string): string {
    const date = new Date(inputDate);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  hasUserRole(role: UserRole): boolean {
    return this.tokenService.hasRole(role);
  }

  private updateSortParams(httpParams: HttpParams): void {
    const sortKeyParam = httpParams.get('sortKey');
    const sortDirectionParam = httpParams.get('sortDirection');

    this.sortDirection = this.getValidSortDirection(sortDirectionParam);
    this.sortColumn = sortKeyParam || '';
  }

  private getValidSortDirection(
    sortDirectionParam: string | null
  ): SortDirection {
    return sortDirectionParam &&
      Object.values(SortDirection).includes(sortDirectionParam as SortDirection)
      ? (sortDirectionParam as SortDirection)
      : SortDirection.NONE;
  }

  private updatePaginationParams(httpParams: HttpParams): void {
    const pageValue = httpParams.get('page');
    const shown = httpParams.get('size');

    this.currentPage = Number(pageValue) || 0;
    this.itemsPerPageShown = Number(shown) || 0;
    this.first = this.currentPage * this.itemsPerPageShown;
  }

  private handleOrderListChange(changes: SimpleChanges): void {
    if (changes['orderList'] && changes['orderList'].currentValue) {
      this.totalRecords = this.totalPages * this.itemsPerPageShown;
    }
  }
}
