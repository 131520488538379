import { Routes } from '@angular/router';
import { OrderComponent } from './features/order/order.component';
import { OrderDetailComponent } from './features/order-detail/order-detail.component';
import { MsalGuard } from '@azure/msal-angular';
import { DummyComponent } from './shared/components/dummy.component';

export const routes: Routes = [
  {
    path: 'order',
    component: OrderComponent,
    canActivate: [MsalGuard],
    title: 'Aufträge | IDR Backoffice',
  },
  { path: 'notKnownUrl', component: DummyComponent, title: 'Test Url' },
  {
    path: 'order/:id',
    component: OrderDetailComponent,
    canActivate: [MsalGuard],
    title: 'Auftrag | IDR Backoffice',
  },
];
