import { Component } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'app-loading-animation',
  standalone: true,
  imports: [LottieComponent],
  templateUrl: './loading-animation.component.html',
  styleUrl: './loading-animation.component.scss',
})
export class LoadingAnimationComponent {
  lottieConfig: AnimationOptions = {
    path: '/assets/loading-spinner.json',
    loop: true,
    autoplay: true,
  };
}
