<div class="login">
  <img
    class="logo"
    src="/assets/idr-solutions-logo.png"
    alt="IDR Solutions Logo"
  />
  <h1>
    Willkommen bei <br />
    IDR Solutions Backoffice
  </h1>
  <button (click)="loginUser()" class="microsoft-button">
    <img
      class="microsoft"
      src="/assets/microsoft-logo.svg"
      alt="Microsoft Logo"
    />Sign in with Microsoft
  </button>
</div>
