import { Component, EventEmitter, Output } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { CommonModule } from '@angular/common';
import { FileService } from './file.service';
import { ToastComponent } from '../toast/toast.component';
import { ToastService } from '../../../core/toast.service';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [
    ButtonComponent,
    FileUploadModule,
    ToastModule,
    CommonModule,
    ToastComponent,
  ],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
})
export class FileUploadComponent {
  @Output() fileUploadSuccessEvent = new EventEmitter();
  toastTitle = '';
  toastText = '';

  constructor(
    private fileService: FileService,
    private toastService: ToastService
  ) {}

  onFileSelected(event: any) {
    for (const file of event.target.files) {
      if (file && file instanceof File && this.isValidExcelFile(file)) {
        this.uploadFile(file);
      } else {
        this.toastService.show(
          'warn',
          'Upload fehlgeschlagen!',
          'Es können nur Excel-Dateien hochgeladen werden.'
        );
      }
    }
  }

  isValidExcelFile(file: File): boolean {
    const allowedExtensions = ['.xls', '.xlsx'];
    const fileExtension = file.name
      .slice(file.name.lastIndexOf('.'))
      .toLowerCase();
    return allowedExtensions.includes(fileExtension);
  }

  uploadFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    this.fileService.uploadExcelFile(formData).subscribe({
      next: (response) => {
        this.fileUploadSuccessEvent.emit();
        this.toastService.show(
          'success',
          'Upload erfolgreich!',
          response.message
        );
      },
     // error: (error: HttpErrorResponse) => {  },
    });
  }
}
