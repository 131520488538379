import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { ButtonComponent } from '../button/button.component';
import { HygieneInspectionStatus } from '../../models/status/hygiene-inspection-status.enum';
import { OrderService } from '../../../core/order.service';
import { HttpParams } from '@angular/common/http';
import { QueryParamsService } from '../../../core/query-params.service';
import { ActivatedRoute } from '@angular/router';
import { FilterParams } from './filter-params.model';
import { LoggerService } from '../../../core/logger.service';
import { OrderStatus } from '../../models/status/order-status.enum';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    DropdownModule,
    FormsModule,
    MultiSelectModule,
    CalendarModule,
    ButtonComponent,
    CommonModule,
  ],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss',
})
export class FilterComponent implements OnInit {
  @Output() filterListEvent = new EventEmitter<HttpParams>();
  auftragsnummer = '';
  standort = '';
  auftraggeber = '';
  filterParams!: FilterParams;
  showClearButton = false;

  rangeDatesStart: Date[] | undefined;
  rangeDatesEnd: Date[] | undefined;
  isDateRangeValid: boolean | undefined = true;

  inspectorFilterItems: string[] | undefined;
  selectedInspector: string | undefined;

  filterStatus: any[] = [];
  selectedStatus: any[] = [];

  paramsStatus: OrderStatus | string = '';
  paramsHygieneStatus: HygieneInspectionStatus | string = '';

  orderStatusItems = [
    { label: 'Angelegt', value: OrderStatus.Created },
    { label: 'Geplant', value: OrderStatus.Planned },
    { label: 'In Durchführung', value: OrderStatus.InProcessByInspector },
    {
      label: 'Vom Prüfer Abgeschlossen',
      value: OrderStatus.FinishedByInspector,
    },
    { label: 'Versandbereit', value: OrderStatus.ReadyForShipment },
    { label: 'Versendet', value: OrderStatus.ShippedToCustomer },
  ];

  hygieneStatusItems = [
    { label: 'Ausstehend', value: HygieneInspectionStatus.Pending },
    { label: 'In Durchführung', value: HygieneInspectionStatus.InProgress },
    { label: 'Im Labor', value: HygieneInspectionStatus.WaitingForLab },
    { label: 'Berichtsprüfung', value: HygieneInspectionStatus.InReview },
    { label: 'Abgeschlossen', value: HygieneInspectionStatus.Completed },
  ];

  statusFilterItems = [
    {
      label: 'Auftragsstatus',
      value: 'order_status',
      items: this.orderStatusItems,
    },
    {
      label: 'Hygieneinspektionsstatus',
      value: 'hygiene_status',
      items: this.hygieneStatusItems,
    },
  ];

  constructor(
    private orderService: OrderService,
    private queryService: QueryParamsService,
    private route: ActivatedRoute,
    private loggerService: LoggerService
  ) {}

  ngOnInit(): void {
    this.getAllInspectors();
    this.loadFilterFromQueryParams();
    this.updateShowClearButton();
  }

  filterOrders() {
    const filterParams: Record<string, any> = this.createFilterObject();
    const httpParams = this.queryService.createHttpParams(filterParams);
    this.filterListEvent.emit(httpParams);
  }

  private createFilterObject(): Record<string, any> {
    const filterParams: Record<string, any> = {};
    const httpParams = this.queryService.getCurrentQueryParams();

    const page = httpParams.get('page');
    const size = httpParams.get('size');

    if (page) {
      filterParams['page'] = page;
    }

    if (size) {
      filterParams['size'] = size;
    }

    // Füge nur die Parameter hinzu, wenn sie nicht leer sind
    if (this.auftragsnummer) {
      filterParams['orderNumber'] = this.auftragsnummer;
    }

    if (this.auftraggeber) {
      filterParams['customer'] = this.auftraggeber;
    }

    if (this.standort) {
      filterParams['location'] = this.standort;
    }

    if (this.selectedInspector) {
      filterParams['inspectorFullName'] = this.selectedInspector;
    }

    if (this.rangeDatesStart?.[0] && this.rangeDatesStart?.[1]) {
      filterParams['startDateRangeStart'] = this.getFormattedDate(
        this.rangeDatesStart[0]
      );
      filterParams['startDateRangeEnd'] = this.getFormattedDate(
        this.rangeDatesStart[1]
      );
    }

    if (this.rangeDatesEnd?.[0] && this.rangeDatesEnd?.[1]) {
      filterParams['endDateRangeStart'] = this.getFormattedDate(
        this.rangeDatesEnd[0]
      );
      filterParams['endDateRangeEnd'] = this.getFormattedDate(
        this.rangeDatesEnd[1]
      );
    }

    const orderStatus: OrderStatus[] = this.selectedStatus.filter(
      (status): status is OrderStatus => {
        return Object.values(OrderStatus).includes(status as OrderStatus);
      }
    );

    const hygieneStatus: OrderStatus[] = this.selectedStatus.filter(
      (status): status is OrderStatus => {
        return Object.values(HygieneInspectionStatus).includes(
          status as HygieneInspectionStatus
        );
      }
    );

    if (orderStatus.length > 0) {
      filterParams['status'] = orderStatus;
    }

    if (hygieneStatus.length > 0) {
      filterParams['hygieneStatus'] = hygieneStatus;
    }
    return filterParams;
  }

  clearFilter() {
    const filterParams: Record<string, any> = {};
    const httpParams = this.queryService.getCurrentQueryParams();

    const page = httpParams.get('page');
    const size = httpParams.get('size');

    if (page) {
      filterParams['page'] = page;
    }

    if (size) {
      filterParams['size'] = size;
    }

    const filter: HttpParams =
      this.queryService.convertQueryParamsToHttpParams(filterParams);

    this.filterListEvent.emit(filter);
    this.showClearButton = false;
  }

  private loadFilterFromQueryParams(): void {
    this.route.queryParams.subscribe((params) => {
      const httpParams =
        this.queryService.convertQueryParamsToHttpParams(params);

      this.auftragsnummer = httpParams.get('orderNumber') || '';
      this.auftraggeber = httpParams.get('customer') || '';
      this.standort = httpParams.get('location') || '';
      this.selectedInspector = httpParams.get('inspectorFullName') || '';

      const statusesArray = httpParams.getAll('status') || [];
      const hygieneStatusesArray = httpParams.getAll('hygieneStatus') || [];

      // Verarbeitung der einzelnen Arrays
      const statuses = statusesArray.flatMap((statusString) =>
        statusString.split(',').map((value) => value.trim())
      );
      const hygieneStatuses = hygieneStatusesArray.flatMap(
        (hygieneStatusString) =>
          hygieneStatusString.split(',').map((value) => value.trim())
      );

      this.selectedStatus = [...statuses, ...hygieneStatuses];

      // Dates Handling
      const startDateRangeStart = httpParams.get('startDateRangeStart') || '';
      const startDateRangeEnd = httpParams.get('startDateRangeEnd') || '';

      const endDateRangeStart = httpParams.get('endDateRangeStart') || '';
      const endDateRangeEnd = httpParams.get('endDateRangeEnd') || '';

      if (startDateRangeStart && startDateRangeEnd) {
        this.rangeDatesStart = [
          new Date(startDateRangeStart),
          new Date(startDateRangeEnd),
        ];
      } else {
        this.rangeDatesStart = undefined;
      }

      if (endDateRangeStart && endDateRangeEnd) {
        this.rangeDatesEnd = [
          new Date(endDateRangeStart),
          new Date(endDateRangeEnd),
        ];
      } else {
        this.rangeDatesEnd = undefined;
      }
    });
  }

  onInspectorChange() {
    this.updateShowClearButton();
  }

  private updateShowClearButton() {
    this.showClearButton = !!this.selectedInspector;
  }

  private getAllInspectors() {
    this.orderService.getAllInspectorNames().subscribe({
      next: (inspectorNames) => {
        this.inspectorFilterItems = inspectorNames;
      },
      error: () => {
        this.loggerService.error('Failed to fetch inspectors');
      },
    });
  }

  private getFormattedDate(date?: Date): string {
    return date ? this.orderService.formatDate(date) : '';
  }
}
