export enum OrderStatus {
  Created = 'CREATED',
  Planned = 'PLANNED',
  InProcessByInspector = 'IN_PROCESS_BY_INSPECTOR',
  FinishedByInspector = 'FINISHED_BY_INSPECTOR',
  ReadyForShipment = 'READY_FOR_SHIPMENT',
  ShippedToCustomer = 'SHIPPED_TO_CUSTOMER',
}

export const OrderStatusLabels: { [key in OrderStatus]: string } = {
  [OrderStatus.Created]: 'Angelegt',
  [OrderStatus.Planned]: 'Geplant',
  [OrderStatus.InProcessByInspector]: 'In Durchführung',
  [OrderStatus.FinishedByInspector]: 'Vom Prüfer Abgeschlossen',
  [OrderStatus.ReadyForShipment]: 'Versandbereit',
  [OrderStatus.ShippedToCustomer]: 'Versendet',
};
