import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() label = '';
  @Input() disabled = false;
  @Input() icon = '';
  @Input() type:
    | 'primary-button'
    | 'primary-button-red'
    | 'primary-icon-button'
    | 'secondary-button'
    | 'secondary-icon-button'
    | 'tertiary-button'
    | 'tertiary-icon-button' = 'primary-button';
}
