import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, forkJoin, of } from 'rxjs';
import { switchMap, catchError, filter, take } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  private requestQueue: { req: HttpRequest<any>; next: HttpHandler }[] = [];

  constructor(private tokenService: TokenService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.tokenService.getToken().pipe(
      switchMap((token) => {
        if (token) {
          if (this.tokenService.isTokenExpiring(token)) {
            // Token aktualisieren und sicherstellen, dass alle Anfragen die neuen Token verwenden
            return this.tokenService
              .acquireToken()
              .pipe(switchMap(() => this.handleRequestWithToken(req, next)));
          } else {
            // Token nicht abgelaufen, Anfrage fortsetzen
            return this.handleRequestWithToken(req, next);
          }
        } else {
          // Token nicht vorhanden, Anfrage in die Warteschlange einreihen und Token-Akquisition starten
          // return this.tokenService
          //   .acquireToken()
          //   .pipe(switchMap(() => this.handleRequestWithToken(req, next)));
          return this.handleTokenNotAvailable(req, next);
        }
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  private handleTokenNotAvailable(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      // Update Token und bearbeite die Warteschlangen-Anfragen
      return this.tokenService.acquireToken().pipe(
        switchMap(() => {
          this.isRefreshing = false;

          // Den aktuellen Token abrufen, nachdem das Update abgeschlossen ist
          return this.tokenService.getToken().pipe(
            switchMap((token) => {
              if (token) {
                // Alle Anfragen in der Warteschlange wiederholen
                const retryRequests = this.requestQueue.map(({ req, next }) => {
                  return this.handleRequestWithToken(req, next);
                });

                this.requestQueue = [];
                return forkJoin(retryRequests).pipe(
                  switchMap((events) => {
                    if (events.length > 0) {
                      return of(events[0]);
                    } else {
                      return throwError(
                        () => new Error('No events returned from forkJoin')
                      );
                    }
                  })
                );
              } else {
                // Kein Token verfügbar
                return throwError(
                  () => new Error('No token available after refresh')
                );
              }
            })
          );
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.requestQueue = [];
          return throwError(() => err);
        })
      );
    } else {
      // Auf den Token warten, während bereits ein Token-Akquisitionsprozess läuft
      return this.refreshTokenSubject.asObservable().pipe(
        filter((token) => token !== null),
        take(1),
        switchMap(() => this.handleRequestWithToken(req, next))
      );
    }
  }

  private handleRequestWithToken(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.tokenService.getToken().pipe(
      switchMap((token) => {
        if (token) {
          const authReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
          return next.handle(authReq);
        } else {
          return throwError(() => new Error('No token available')); // Updated throwError usage
        }
      })
    );
  }
}
