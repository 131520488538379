import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
export interface ToastMessage {
  type: 'warn' | 'success' | 'info';
  title: string;
  message: string;
}
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private messageSubject = new BehaviorSubject<ToastMessage | null>(null);
  message$ = this.messageSubject.asObservable();

  show(type: 'warn' | 'success' | 'info', title: string, message: string) {
    this.messageSubject.next({ type, title, message });
  }
}
