import { Injectable, NgZone } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';
import { LoggerService } from './logger.service';
import { ToastService } from './toast.service';
import { UserRole } from './user-role.enum';
import { throwError } from 'rxjs';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

interface JwtPayload {
  groups?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  public token$: Observable<string | null> = this.tokenSubject.asObservable();

  constructor(
    private msalService: MsalService,
    private loggerService: LoggerService,
    private toastService: ToastService,
    private zone: NgZone
  ) {
    this.updateToken();
    this.startTokenRenewal();
  }

  updateToken() {
    this.acquireToken()
      .pipe(
        catchError((err) => {
          if (err instanceof InteractionRequiredAuthError) {
            // Fallback to popup if silent token acquisition fails due to interaction requirement
            return this.acquireTokenViaPopup();
          } else {
            this.loggerService.error('Token acquisition error:', err);
            this.toastService.show(
              'warn',
              'Token acquisition error',
              'Der Auth-Token konnte nicht richtig ausgelesen werden.'
            );
            return throwError(() => err);
          }
        })
      )
      .subscribe((token) => {
        this.loggerService.log(
          `Token acquired:`,
          token ? 'via silent or popup' : 'not acquired'
        );
        this.tokenSubject.next(token);
      });
  }

  acquireToken(): Observable<string | null> {
    return this.msalService
      .acquireTokenSilent({
        scopes: ['api://1fef3d25-758b-4fdd-94e6-e90d4b172e08/.default'],
      })
      .pipe(
        switchMap((result) => [result.accessToken]),
        catchError((err) => {
          this.loggerService.error('Silent token acquisition error:', err);
          this.toastService.show(
            'warn',
            'Silent token acquisition error',
            'Der Silent Auth-Token konnte nicht richtig ausgelesen werden.'
          );
          return throwError(() => err);
        })
      );
  }

  private acquireTokenViaPopup(): Observable<string | null> {
    return this.msalService
      .acquireTokenPopup({
        scopes: ['api://1fef3d25-758b-4fdd-94e6-e90d4b172e08/.default'],
      })
      .pipe(
        switchMap((result) => [result.accessToken]),
        catchError((err) => {
          this.loggerService.error('Pop-Up Error:', err);
          this.toastService.show(
            'warn',
            'Pop-Up Error',
            'Der PopUp Auth-Token konnte nicht ausgelesen werden. Das Pop-Up für das Microsoft Login wurde blockiert.'
          );
          return throwError(() => err);
        })
      );
  }

  private startTokenRenewal(): void {
    this.zone.runOutsideAngular(() => {
      interval(5 * 60 * 1000).subscribe(() => {
        const currentToken = this.tokenSubject.getValue();

        if (currentToken && this.isTokenExpiring(currentToken)) {
          this.loggerService.log('Start renewing access token...');
          this.updateToken();
        }
      });
    });
  }

  isTokenExpiring(token: string): boolean {
    const decodedToken = jwtDecode(token);
    if (decodedToken && decodedToken.exp) {
      const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
      const currentTime = Date.now();

      return expirationTime - currentTime < 5 * 60 * 1000; // Check if token expires in the next 5 minutes
    }
    return false;
  }

  public getToken(): Observable<string | null> {
    return this.token$;
  }

  public hasRole(roleId: UserRole): boolean {
    const token = this.tokenSubject.getValue();

    if (token) {
      const decodedToken: JwtPayload = jwtDecode(token);
      if (decodedToken && decodedToken.groups) {
        return decodedToken.groups.includes(roleId);
      }
    }
    return false;
  }
}
