export enum AggregateInspectionStatus {
  OnGoing = 'ON_GOING',
  PartlyInspected = 'PARTLY_INSPECTED',
  Inspected = 'INSPECTED',
  NotInspected = 'NOT_INSPECTED',
}

export const AggregateInspectionStatusLabels: {
  [key in AggregateInspectionStatus]: string;
} = {
  [AggregateInspectionStatus.OnGoing]: 'Im Gange',
  [AggregateInspectionStatus.PartlyInspected]: 'Teilabgeschlossen',
  [AggregateInspectionStatus.Inspected]: 'Abgeschlossen',
  [AggregateInspectionStatus.NotInspected]: 'Abgebrochen',
};
