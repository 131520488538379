import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-detail-card',
  standalone: true,
  imports: [NgStyle],
  templateUrl: './detail-card.component.html',
  styleUrls: ['./detail-card.component.scss'],
})
export class DetailCardComponent {
  @Input() title = '';
  @Input() zohoId = '';
  @Input() backgroundImage = '';
  @Input() hoverBackgroundImage = '';

  private isHovered = false;

  get currentStyles(): Record<string, string> {
    const imageUrl = this.isHovered
      ? this.hoverBackgroundImage
      : this.backgroundImage;
    return {
      'background-image': `url(${imageUrl})`,
      'background-repeat': 'no-repeat',
      'background-position': 'bottom right',
      'background-size': 'contain',
      transition: 'background-image 0.5s ease',
    };
  }
  navigateToZoho(zohoId: string): void {
    window.open(
      `https://crm.zoho.eu/crm/org20093979379/tab/Accounts/${zohoId}`,
      '_blank'
    );
  }
  onMouseEnter(): void {
    this.isHovered = true;
  }

  onMouseLeave(): void {
    this.isHovered = false;
  }
}
