import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { LoggerService } from './logger.service';
import { ToastService } from './toast.service';
import { OrderDetailResponse } from '../shared/models/order/order-detail-response.model';
import { InspectorResponse } from '../shared/models/order/inspector-response.model';
import { OrderResponse } from '../shared/models/order/order-response.model';
import { OrderStatus } from '../shared/models/status/order-status.enum';
import { HygieneInspectionStatus } from '../shared/models/status/hygiene-inspection-status.enum';
import { OrderPages } from '../shared/models/order/OrderPages.model';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private readonly _apiUrlOrder = `${
    import.meta.env.NG_APP_API_URL
  }/backoffice/v1/orders/`;

  private readonly _apiUrlInspectors = `${
    import.meta.env.NG_APP_API_URL
  }/backoffice/v1/inspectors/`;
  private readonly _apiUrlHygieneInspections = `${
    import.meta.env.NG_APP_API_URL
  }/backoffice/v1/hygieneInspections/`;

  constructor(
    private http: HttpClient,
    private loggerService: LoggerService,
    private toastService: ToastService
  ) {}

  getPaginatedOrder(params: HttpParams): Observable<OrderPages> {
    return this.http
      .get<{
        content: OrderResponse[];
        totalPages: number;
      }>(this._apiUrlOrder, { params })
      .pipe(
        map((response) => response),
        catchError(this.handleError.bind(this))
      );
  }

  getOrderDetail(id: string): Observable<OrderDetailResponse> {
    return this.http
      .get<OrderDetailResponse>(this._apiUrlOrder + id)
      .pipe(catchError(this.handleError.bind(this)));
  }

  deleteOrder(id: string): Observable<any> {
    return this.http
      .put<OrderDetailResponse>(`${this._apiUrlOrder}${id}/delete`, {})
      .pipe(catchError(this.handleError.bind(this)));
  }

  getAllInspectorNames(): Observable<string[]> {
    return this.http
      .get<InspectorResponse[]>(this._apiUrlInspectors)
      .pipe(
        map((inspectors) =>
          inspectors.map(
            (inspector) => `${inspector.firstName} ${inspector.lastName}`
          )
        )
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  getAllInspectors(): Observable<InspectorResponse[]> {
    return this.http
      .get<InspectorResponse[]>(this._apiUrlInspectors)
      .pipe(catchError(this.handleError.bind(this)));
  }

  updateOrderStartDate(
    id: string,
    startDate: Date | null
  ): Observable<any> {
    let params = new HttpParams();
    if (startDate) {
      const formattedDate = this.formatDate(startDate);
      params = params.set('startDate', formattedDate);
    }

    return this.http
      .put(`${this._apiUrlOrder}${id}/update/startDate`, null, { params })
      .pipe(catchError(this.handleError.bind(this)));
  }

  updateOrderInspectors(
    orderId: string,
    inspectorIdList: string[]
  ): Observable<any> {
    let params = new HttpParams();

    inspectorIdList.forEach((id) => {
      params = params.append('inspector', id);
    });

    return this.http
      .put(`${this._apiUrlOrder}${orderId}/inspectors/set`, null, { params })
      .pipe(catchError(this.handleError.bind(this)));
  }

  updateOrderStatus(id: string, state: OrderStatus): Observable<any> {
    const params = new HttpParams().set('status', state);
    return this.http
      .put(`${this._apiUrlOrder}${id}/update/status`, null, {
        params,
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  updateHygieneInspectionStatus(
    hygieneInspectionId: string,
    state: HygieneInspectionStatus
  ): Observable<any> {
    const params = new HttpParams().set('status', state);
    return this.http
      .put(
        `${this._apiUrlHygieneInspections}${hygieneInspectionId}/update/status`,
        null,
        {
          params,
        }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Ein unbekannter Fehler ist aufgetreten!';

    if (error.error instanceof ErrorEvent) {
      // Client-seitiger oder Netzwerkfehler
      errorMessage = `Ein Fehler ist aufgetreten: ${error.error.message}`;
    } else {
      // Backend-seitiger Fehler
      errorMessage =
        `Backend hat mit Status ${error.status} geantwortet. ` +
        `Grund dafür ist: ${error.message}`;
    }

    this.loggerService.error(errorMessage);
    this.toastService.show('warn', 'Ein Fehler ist aufgetreten', errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
