<div class="filters">
  <div class="filter">
    <label for="auftragsnummer">Auftragsnummer</label>
    <p-iconField iconPosition="left">
      <p-inputIcon styleClass="pi pi-search" />
      <input
        id="auftragsnummer"
        type="text"
        pInputText
        placeholder="Suchen"
        [(ngModel)]="auftragsnummer"
        (keydown.enter)="filterOrders()"
      />
    </p-iconField>
  </div>
  <div class="filter">
    <label for="standort">Auftraggeber</label>
    <p-iconField iconPosition="left">
      <p-inputIcon styleClass="pi pi-search" />
      <input
        id="auftraggeber"
        type="text"
        pInputText
        placeholder="Suchen"
        [(ngModel)]="auftraggeber"
        (keydown.enter)="filterOrders()"
      />
    </p-iconField>
  </div>
  <div class="filter">
    <label for="standort">Standort</label>
    <p-iconField iconPosition="left">
      <p-inputIcon styleClass="pi pi-search" />
      <input
        id="standort"
        type="text"
        pInputText
        placeholder="Suchen"
        [(ngModel)]="standort"
        (keydown.enter)="filterOrders()"
      />
    </p-iconField>
  </div>
  <div class="filter inspector-filter">
    <label for="inspector">Prüfende Person</label>
    <p-dropdown
      inputId="inspector"
      [options]="inspectorFilterItems"
      [(ngModel)]="selectedInspector"
      placeholder="Alle"
      [showClear]="showClearButton"
      (ngModelChange)="onInspectorChange()"
    />
  </div>

  <div class="filter date-filter">
    <label for="startDate">Startdatum</label>
    <p-calendar
      inputId="startDate"
      [(ngModel)]="rangeDatesStart"
      selectionMode="range"
      [showButtonBar]="true"
      [iconDisplay]="'input'"
      [showIcon]="true"
      placeholder="Datum"
      dateFormat="dd.mm.yy"
    />
  </div>
  <div class="filter date-filter">
    <label for="endDate">Enddatum</label>
    <p-calendar
      inputId="endDate"
      [(ngModel)]="rangeDatesEnd"
      selectionMode="range"
      [showButtonBar]="true"
      [iconDisplay]="'input'"
      [showIcon]="true"
      placeholder="Datum"
      dateFormat="dd.mm.yy"
    />
  </div>
  <div class="filter">
    <label for="status">Status</label>
    <p-multiSelect
      (keydown.enter)="filterOrders()"
      inputId="status"
      [options]="statusFilterItems"
      [group]="true"
      [(ngModel)]="selectedStatus"
      placeholder="Alle"
      scrollHeight="250px"
      display="chip"
    >
      <ng-template let-group pTemplate="group">
        <div class="flex align-items-center">
          <span>{{ group.label }}</span>
        </div>
      </ng-template>
    </p-multiSelect>
  </div>
  <app-button (click)="filterOrders()" type="primary-button" label="Filtern" />

  <app-button
    (click)="clearFilter()"
    type="secondary-button"
    label="Filter zurücksetzen"
  />
</div>
