<table class="custom-table">
  <thead>
    <tr>
      @for (column of columns; track $index) {
        <th [ngClass]="'align-' + column.align">
          <div [ngClass]="'table-head-' + column.align">
            {{ column.label }}
            @if (column.allowSort) {
              @if (sortColumn === column.key) {
                <img
                  class="active"
                  (click)="setSort(column.key)"
                  (keyup.enter)="setSort(column.key)"
                  (keyup.space)="setSort(column.key)"
                  tabindex="0"
                  [src]="
                    'assets/filter-' +
                    (sortDirection === SortDirection.ASC ? 'asc' : 'desc') +
                    '.svg'
                  "
                  alt="Filter Icon"
                />
              } @else {
                <img
                  (click)="setSort(column.key)"
                  (keyup.enter)="setSort(column.key)"
                  (keyup.space)="setSort(column.key)"
                  tabindex="0"
                  src="assets/filter.svg"
                  alt="Filter Icon"
                />
              }
            }
          </div>
        </th>
      }
    </tr>
  </thead>
  <tbody>
    @for (item of orderList; track item.id) {
      <tr>
        <td class="responsive-cell">
          <span>{{ item.orderNumber }}</span>
        </td>
        <td class="responsive-cell">
          <div class="cell-styling">
            <span class="bold-styling">{{ item.customer.name }}</span>
            <span>{{ item.customer.zipCode }} {{ item.customer.city }}</span>
            <span>{{ item.customer.country }}</span>
          </div>
        </td>
        <td class="responsive-cell">
          <div class="cell-styling">
            <span class="bold-styling">{{ item.location.name }}</span>
            <span>{{ item.location.zipCode }} {{ item.location.city }}</span>
            <span>{{ item.location.country }}</span>
          </div>
        </td>
        <td class="responsive-cell">
          <div class="cell-styling">
            @for (
              inspector of item.inspectors;
              track inspector.id;
              let index = $index
            ) {
              <span>{{ inspector.firstName }} {{ inspector.lastName }}</span>
            }
            @if (item.inspectors.length === 0) {
              -
            }
          </div>
        </td>
        <td class="responsive-cell">
          @if (item.startDate) {
            <span>{{ formatDate(item.startDate) }}</span>
          } @else {
            -
          }
        </td>
        <td class="responsive-cell">
          @if (item.endDate) {
            <span>{{ formatDate(item.endDate) }}</span>
          } @else {
            -
          }
        </td>
        <td class="vertical-align hover-pointer">
          <app-chip [status]="item.status" />
        </td>
        <td class="vertical-align hover-pointer">
          @if (item.hygiene) {
            <app-chip [status]="item.hygiene.status" />
          } @else {
            -
          }
        </td>
        <td>
          <div>
            <a
              class="link"
              [href]="
                'https://crm.zoho.eu/crm/org20093979379/tab/SalesOrders/' +
                item.zohoId
              "
              (click)="stopEventBubbling($event)"
              >Zoho</a
            >
          </div>
        </td>
        <td>
          <a
            class="external-link"
            [routerLink]="['/order', item.orderNumber]"
            (click)="openDetailPage(item.orderNumber)"
            ><img src="assets/external-link.svg"
          /></a>
        </td>
      </tr>
    }
  </tbody>
  <tfoot>
    <tr>
      <td colspan="8">
        <div class="pagination-controls">
          <p-paginator
            (onPageChange)="onPageChange($event)"
            [first]="first"
            [rows]="itemsPerPageShown"
            [rowsPerPageOptions]="itemsPerPageOptions"
            [totalRecords]="totalRecords"
          />
        </div>
      </td>
    </tr>
  </tfoot>
</table>
