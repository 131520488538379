import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  updateQueryParams(httpParams: HttpParams) {
    const paramsObj: Record<string, any> = {};

    httpParams.keys().forEach((key) => {
      const values = httpParams.getAll(key);

      if (values) {
        if (values.length > 1) {
          paramsObj[key] = values;
        } else {
          paramsObj[key] = values[0];
        }
      }
    });

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: paramsObj,
    });
  }

  convertQueryParamsToHttpParams(params: any): HttpParams {
    let httpParams = new HttpParams();

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        httpParams = httpParams.set(key, params[key]);
      }
    });

    return httpParams;
  }

  createHttpParams(params: any): HttpParams {
    let httpParams = new HttpParams();

    for (const key of Object.keys(params)) {
      if (params[key] !== undefined && params[key] !== null) {
        if (Array.isArray(params[key])) {
          params[key].forEach((value) => {
            httpParams = httpParams.append(key, value);
          });
        } else {
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }
    return httpParams;
  }

  getCurrentQueryParams(): HttpParams {
    const params: Record<string, string | string[]> = {};

    this.route.snapshot.queryParamMap.keys.forEach((key) => {
      const value = this.route.snapshot.queryParamMap.getAll(key);
      params[key] = value.length > 1 ? value : value[0];
    });

    return this.convertQueryParamsToHttpParams(params);
  }
}
