import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ChipComponent } from '../chip/chip.component';
import { Router } from '@angular/router';

import { AggregateResponse } from '../../models/aggregates/aggregate-response.model';
import { CommonModule } from '@angular/common';
import { FileService } from '../file-upload/file.service';

import {
  AggregateType,
  AggregateTypeLabels,
} from '../../models/aggregates/aggregate-type.enum';
import { AggregateReportState } from '../../models/status/aggregate-report-state.enum';
import { OrderDetailResponse } from '../../models/order/order-detail-response.model';
import { OrderStatus } from '../../models/status/order-status.enum';

@Component({
  selector: 'app-detail-table',
  standalone: true,
  imports: [ChipComponent, CommonModule],
  templateUrl: './detail-table.component.html',
  styleUrls: ['./detail-table.component.scss'],
})
export class DetailTableComponent implements OnInit {
  @Input() orderDetail: OrderDetailResponse | undefined;
  @Input() aggregates: AggregateResponse[] = [];

  sortedData: AggregateResponse[] = this.aggregates;
  sortColumn = '';
  sortDirection: 'asc' | 'desc' | '' = '';
  OrderStatus = OrderStatus;
  AggregateReportState = AggregateReportState;

  columns = [
    { key: 'idrId', label: 'IDR-ID', align: 'left' },
    { key: 'name', label: 'Anlagenname', align: 'left' },
    { key: 'type', label: 'Typ', align: 'left' },
    { key: 'buildingName', label: 'Aufstellungsort', align: 'left' },
    { key: 'customerProvidedId', label: 'Kunden-ID', align: 'left' },
    {
      key: 'hygiene.inspectionDate',
      label: 'Insp.-Datum',
      align: 'left',
    },
    {
      key: 'hygiene.status',
      label: 'Insp.-Status',
      align: 'center',
    },
    {
      key: 'aggregateReport.reportId',
      label: 'Bericht',
      align: 'center',
    },
  ];

  private openDropdownId: number | null = null;

  constructor(
    private router: Router,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    this.updateData();
  }

  downloadSingleReport(aggregateId: string): void {
    this.fileService
      .downloadSingleHygieneAggregateReport(aggregateId)
      .subscribe((response) => {
        this.handleFileDownload(response);
      });
  }

  updateData(): void {
    const sortedData = [...this.aggregates];

    if (this.sortColumn) {
      sortedData.sort((a, b) => {
        const aVal = this.getNestedValue(a, this.sortColumn);
        const bVal = this.getNestedValue(b, this.sortColumn);

        const aValue = aVal !== null && aVal !== undefined ? aVal : '';
        const bValue = bVal !== null && bVal !== undefined ? bVal : '';

        if (aValue === '' && bValue !== '') {
          return 1;
        }
        if (aValue !== '' && bValue === '') {
          return -1;
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return this.sortDirection === 'asc'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
          return this.sortDirection === 'asc'
            ? aValue - bValue
            : bValue - aValue;
        }

        return 0;
      });
    }

    this.sortedData = sortedData;
  }

  setSort(column: string): void {
    if (this.sortColumn === column) {
      this.sortDirection =
        this.sortDirection === 'asc'
          ? 'desc'
          : this.sortDirection === 'desc'
            ? ''
            : 'asc';
      if (this.sortDirection === '') {
        this.sortColumn = '';
      }
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }

    this.updateData();
  }

  getLabel(type: AggregateType): string {
    return AggregateTypeLabels[type];
  }

  toggleDropdown(id: number, event: MouseEvent): void {
    event.stopPropagation();
    if (this.openDropdownId === id) {
      this.openDropdownId = null;
    } else {
      this.openDropdownId = id;
    }
  }

  isDropdownOpen(id: number): boolean {
    return this.openDropdownId === id;
  }
  viewDetail(id: number): void {
    this.router.navigate(['/order', id]);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const dropdowns = document.querySelectorAll('.dropdown');
    dropdowns.forEach((dropdown) => {
      const button = dropdown.querySelector('.dropdown-toggle') as HTMLElement;
      const menu = dropdown.querySelector('.dropdown-menu') as HTMLElement;

      if (
        button &&
        menu &&
        !button.contains(target) &&
        !menu.contains(target)
      ) {
        this.openDropdownId = null;
      }
    });
  }

  private handleFileDownload(response: any): void {
    const contentDispositionHeader =
      response.headers.get('Content-Disposition') || '';

    const fileName = this.fileService.extractFilename(contentDispositionHeader);

    const blob = response.body;

    if (blob) {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(url);
    }
  }

  private getNestedValue(obj: any, path: string) {
    return path.split('.').reduce((o, p) => {
      return o ? o[p] : undefined;
    }, obj);
  }
}
