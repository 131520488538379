import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  PopupRequest,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { LoginComponent } from './features/login/login.component';
import { NavigationComponent } from './features/navigation/navigation.component';
import { LoadingAnimationComponent } from './shared/components/loading-animation/loading-animation.component';
import { ErrorComponent } from './shared/components/error/error.component';
import { LoggerService } from './core/logger.service';
import { ToastComponent } from './shared/components/toast/toast.component';
import { ToastService } from './core/toast.service';
import { PrimeNGConfigService } from './core/primeng-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    LoginComponent,
    NavigationComponent,
    LoadingAnimationComponent,
    ErrorComponent,
    ToastComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  env = import.meta.env.NG_APP_ENV;
  isIframe = false;
  loginDisplay = false;
  isLoading = false;
  error: Error | null = null;
  userName: string | undefined = undefined;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(PLATFORM_ID) private platformId: object,
    private router: Router,
    private logger: LoggerService,
    private toastService: ToastService,
    private primeNGService: PrimeNGConfigService
  ) {}

  ngOnInit(): void {
    this.logger.log('AppComponent initialized');

    if (isPlatformBrowser(this.platformId)) {
      this.authService.handleRedirectObservable().subscribe();
      //this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

      this.setLoginDisplay();

      this.authService.instance.enableAccountStorageEvents();
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter(
            (msg: EventMessage) =>
              msg.eventType === EventType.ACCOUNT_ADDED ||
              msg.eventType === EventType.ACCOUNT_REMOVED
          )
        )
        .subscribe(() => {
          this.logger.log('Account event triggered');
          if (this.authService.instance.getAllAccounts().length === 0) {
            this.logger.warn('No accounts found, redirecting to home');
            window.location.pathname = '/';
          } else {
            this.setLoginDisplay();
          }
        });

      this.msalBroadcastService.inProgress$
        .pipe(
          filter(
            (status: InteractionStatus) => status === InteractionStatus.None
          ),
          takeUntil(this._destroying$)
        )
        .subscribe(() => {
          this.logger.log('MSAL interaction complete');
          this.setLoginDisplay();
          this.checkAndSetActiveAccount();
        });
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginPopup() {
    if (isPlatformBrowser(this.platformId)) {
      this.isLoading = true;
      this.logger.log('Login popup started');
      const request = this.msalGuardConfig.authRequest
        ? ({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        : undefined;

      this.authService
        .loginPopup(request)
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.logger.log('Login popup finished');
          })
        )
        .subscribe({
          next: (response: AuthenticationResult) => {
            this.logger.log('Login successful', response.account);
            this.authService.instance.setActiveAccount(response.account);
            this.router.navigate(['/order']);
          },
          error: (err) => {
            this.handleLoginError(err);
            this.isLoading = false;
          },
        });
    }
  }

  logout(popup?: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      if (popup) {
        this.logger.log('Logout via popup');
        this.authService.logoutPopup({
          mainWindowRedirectUri: '/',
        });
      } else {
        this.logger.log('Logout via redirect');
        this.authService.logoutRedirect();
      }
    }
  }

  private handleLoginError(error: Error): void {
    this.logger.error('Error during login', error);
    this.toastService.show('warn', 'Error during login', `${error}`);
    this.error = error;
  }

  ngOnDestroy(): void {
    this.logger.log('AppComponent destroyed');
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
