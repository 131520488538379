import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonComponent } from '../button/button.component';
import { CalendarModule } from 'primeng/calendar';
import { OrderService } from '../../../core/order.service';

import { LoggerService } from '../../../core/logger.service';
import { OrderStatus } from '../../models/status/order-status.enum';
import { OrderDetailResponse } from '../../models/order/order-detail-response.model';
import { InspectorResponse } from '../../models/order/inspector-response.model';
import { firstValueFrom } from 'rxjs';

interface Option {
  id: string | OrderStatus;
  name: string;
}
@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [
    DialogModule,
    ButtonModule,
    InputTextModule,
    FormsModule,
    IconFieldModule,
    InputIconModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    ButtonComponent,
  ],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
})
export class DialogComponent implements OnInit, OnChanges {
  @Input() orderDetail!: OrderDetailResponse;
  @Output() orderChangedEvent = new EventEmitter();
  OrderStatus = OrderStatus;

  visible = false;
  dropdownItems: string[] = [];

  initialDate: Date | null = null;
  selectedDate: Date | null = null;
  minDate?: Date;

  allInspectors: InspectorResponse[] = [];
  inspectorOptions: Option[] = [];
  selectedInspectorIds: string[] = [];
  originalSelectedIds: string[] = [];

  constructor(
    private orderService: OrderService,
    private loggerService: LoggerService
  ) {}

  ngOnInit(): void {
    this.loadInspectors();
    this.minDate = new Date();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges) {
    this.initializeDates();
    this.setInitialInspectorSelection();
  }

  private loadInspectors(): void {
    this.orderService.getAllInspectors().subscribe({
      next: (inspectors) => {
        this.allInspectors = inspectors;
        this.inspectorOptions = this.transformInspectorsToOptions(inspectors);
        this.setInitialInspectorSelection();
      },
      error: () => {
        this.loggerService.error('Failed to fetch inspector list');
      },
    });
  }

  private transformInspectorsToOptions(
    inspectors: InspectorResponse[]
  ): Option[] {
    return inspectors.map((inspector) => ({
      id: inspector.id,
      name: `${inspector.firstName} ${inspector.lastName}`,
    }));
  }

  private setInitialInspectorSelection(): void {
    this.originalSelectedIds = this.orderDetail.inspectors
      .filter((inspector) =>
        this.inspectorOptions.some((option) => option.id === inspector.id)
      )
      .map((inspector) => inspector.id);

    this.selectedInspectorIds = [...this.originalSelectedIds];
  }

  private initializeDates(): void {
    if (this.orderDetail.startDate) {
      this.initialDate = new Date(this.orderDetail.startDate);
    } else {
      this.initialDate = null;
    }
    this.selectedDate = this.initialDate;
  }

  private async updateOrder(): Promise<void> {
    try {
      if (!this.dateEquals(this.selectedDate, this.initialDate)) {
        await firstValueFrom(
          this.orderService.updateOrderStartDate(
            this.orderDetail.id,
            this.selectedDate
          )
        );

        this.orderChangedEvent.emit();
      }
      if (
        !this.arraysAreEqual(
          this.selectedInspectorIds,
          this.originalSelectedIds
        )
      ) {
        await firstValueFrom(
          this.orderService.updateOrderInspectors(
            this.orderDetail.id,
            this.selectedInspectorIds
          )
        );

        this.orderChangedEvent.emit();
      }
    } catch (error) {
      this.loggerService.error('Failed to update order', error);
    }
  }

  arraysAreEqual(a: any[], b: any[]): boolean {
    if (a.length !== b.length) return false;
    return a.every((value, index) => value === b[index]);
  }

  dateEquals(a: Date | null, b: Date | null): boolean {
    if (a === null && b === null) return true;
    if (a === null || b === null) return false;
    return (
      a.getDay() === b.getDay() &&
      a.getMonth() === b.getMonth() &&
      a.getFullYear() == b.getFullYear()
    );
  }
  save() {
    this.visible = false;
    this.updateOrder();
    this.loggerService.log(
      `Order detail ${this.orderDetail.orderNumber} was updated.`
    );
  }

  cancel(): void {
    this.selectedDate = this.initialDate;
    this.selectedInspectorIds = [...this.originalSelectedIds];
    this.visible = false;
    this.loggerService.log(
      `Cancel update of order detail ${this.orderDetail.orderNumber}.`
    );
  }

  showDialog() {
    this.visible = true;
  }
}
