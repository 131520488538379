import { Component, Input } from '@angular/core';
import {
  HygieneInspectionStatus,
  HygieneInspectionStatusLabels,
} from '../../models/status/hygiene-inspection-status.enum';

import { TooltipModule } from 'primeng/tooltip';
import {
  AggregateInspectionStatus,
  AggregateInspectionStatusLabels,
} from '../../models/status/aggregate-inspection-status.enum';
import {
  OrderStatus,
  OrderStatusLabels,
} from '../../models/status/order-status.enum';

@Component({
  selector: 'app-chip',
  standalone: true,
  imports: [TooltipModule],
  templateUrl: './chip.component.html',
  styleUrl: './chip.component.scss',
})
export class ChipComponent {
  @Input() imgSrc = '';
  @Input() status:
    | HygieneInspectionStatus
    | OrderStatus
    | AggregateInspectionStatus
    | undefined;
  @Input() hasText = false;

  orderStatus = OrderStatus;
  hygieneStatus = HygieneInspectionStatus;

  getStatusLabel(): string {
    if (Object.values(OrderStatus).includes(this.status as OrderStatus)) {
      return OrderStatusLabels[this.status as OrderStatus];
    } else if (
      Object.values(HygieneInspectionStatus).includes(
        this.status as HygieneInspectionStatus
      )
    ) {
      return HygieneInspectionStatusLabels[
        this.status as HygieneInspectionStatus
      ];
    } else if (
      Object.values(AggregateInspectionStatus).includes(
        this.status as AggregateInspectionStatus
      )
    ) {
      return AggregateInspectionStatusLabels[
        this.status as AggregateInspectionStatus
      ];
    }
    return 'Unknown Status';
  }
  statusImageMap: Record<string, string> = {
    [OrderStatus.Created]: '/assets/icons/edit.svg',
    [OrderStatus.Planned]: '/assets/icons/planned.svg',
    [OrderStatus.InProcessByInspector]:
      '/assets/icons/in_process_by_inspector.svg',
    [OrderStatus.FinishedByInspector]:
      '/assets/icons/finished_by_inspector.svg',
    [OrderStatus.ReadyForShipment]: '/assets/icons/ready_for_shippment.svg',
    [OrderStatus.ShippedToCustomer]: '/assets/icons/shipped_to_customer.svg',
    [HygieneInspectionStatus.Pending]: '/assets/icons/pending.svg',
    [HygieneInspectionStatus.InProgress]: '/assets/icons/in_progress.svg',
    [HygieneInspectionStatus.Completed]: '/assets/icons/completed.svg',
    [HygieneInspectionStatus.WaitingForLab]:
      '/assets/icons/waiting_for_lab.svg',
    [HygieneInspectionStatus.InReview]: '/assets/icons/in_review.svg',
    [AggregateInspectionStatus.OnGoing]: '/assets/icons/on_going.svg',
    [AggregateInspectionStatus.NotInspected]: '/assets/icons/not_inspected.svg',
    [AggregateInspectionStatus.PartlyInspected]:
      '/assets/icons/partly_inspected.svg',
    [AggregateInspectionStatus.Inspected]: '/assets/icons/inspected.svg',
  };

  getImageSrc(): string {
    return this.statusImageMap[this.status || ''] || '/assets/default.svg'; // Fallback-Bild falls der Status nicht gefunden wird
  }
}
