<div class="aggregates-analysis">
  <div class="aggregate-status">
    <h2>Auftrags- und Hygienestatus</h2>
    <div class="status">
      <div class="status-icon-background">
        <app-chip [status]="orderDetail.status" />
      </div>
      <div class="status-text">
        <h6>Auftragsstaus</h6>
        <p>{{ getStatusLabel(orderDetail.status) }}</p>
      </div>
    </div>

    <div class="status">
      <div class="status-icon-background">
        <app-chip [status]="orderDetail.hygiene?.status" />
      </div>
      <div class="status-text">
        <h6>Hygienestatus</h6>
        <p>{{ getStatusLabel(orderDetail.hygiene?.status) }}</p>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="aggregate-chart">
    <h2>
      Inspektionsstatus -
      <span>{{ getTotalAggregates(orderDetail.aggregates) }} Anlage(n)</span>
    </h2>
    <p-chart type="doughnut" [data]="data" [options]="options" />
  </div>
</div>
