<table class="custom-table">
  <thead>
    <tr>
      @for (column of columns; track $index) {
        <th [ngClass]="'align-' + column.align">
          <div c [ngClass]="'table-head-' + column.align">
            <span>{{ column.label }}</span>
            @if (sortColumn === column.key) {
              <img
                class="active"
                (click)="setSort(column.key)"
                (keyup.enter)="setSort(column.key)"
                (keyup.space)="setSort(column.key)"
                tabindex="0"
                [src]="
                  'assets/filter-' +
                  (sortDirection === 'asc' ? 'asc' : 'desc') +
                  '.svg'
                "
                alt="Filter Icon"
              />
            } @else {
              <img
                (click)="setSort(column.key)"
                (keyup.enter)="setSort(column.key)"
                (keyup.space)="setSort(column.key)"
                tabindex="0"
                src="assets/filter.svg"
                alt="Filter Icon"
              />
            }
          </div>
        </th>
      }
    </tr>
  </thead>
  <tbody>
    @for (
      aggregate of sortedData.length ? sortedData : aggregates;
      track aggregate.id
    ) {
      <tr>
        <td class="responsive-cell">
          <div class="cell-styling">
            <span>{{ aggregate.idrId ? aggregate.idrId : '-' }}</span>
          </div>
        </td>
        <td class="responsive-cell">
          <div class="cell-styling">
            <span>{{ aggregate.name ? aggregate.name : '-' }}</span>
          </div>
        </td>
        <td class="responsive-cell">
          <div class="cell-styling">
            <span>{{ aggregate.type ? getLabel(aggregate.type) : '-' }}</span>
          </div>
        </td>
        <td class="responsive-cell">
          <div class="cell-styling">
            @if (
              aggregate.buildingName ||
              aggregate.floor ||
              aggregate.installationSite
            ) {
              <span class="bold-styling">{{ aggregate.buildingName }}</span>
              <span>{{ aggregate.floor }}</span>
              <span>{{ aggregate.installationSite }}</span>
            } @else {
              -
            }
          </div>
        </td>

        <td class="responsive-cell">
          <div class="cell-styling">
            <span>{{
              aggregate.customerProvidedId ? aggregate.customerProvidedId : '-'
            }}</span>
          </div>
        </td>
        <td class="responsive-cell">
          <span>{{
            aggregate.hygiene?.inspectionDate
              ? aggregate.hygiene?.inspectionDate
              : '-'
          }}</span>
        </td>
        <td>
          @if (aggregate.hygiene?.status) {
            <app-chip [status]="aggregate.hygiene?.status" />
          } @else {
            -
          }
        </td>

        <td class="align-middle">
          @if (
            aggregate.aggregateReport?.reportId &&
            aggregate.aggregateReport?.reportState ===
              AggregateReportState.GENERATED
          ) {
            <div class="cell-styling link">
              <a
                (click)="downloadSingleReport(aggregate.id)"
                (keyup.enter)="downloadSingleReport(aggregate.id)"
                (keyup.space)="downloadSingleReport(aggregate.id)"
                tabindex="0"
                >{{ aggregate.aggregateReport?.reportId }}</a
              >
            </div>
          } @else if (
            aggregate.aggregateReport?.reportId &&
            aggregate.aggregateReport?.reportState ===
              AggregateReportState.NOT_GENERATED
          ) {
            <span>{{ aggregate.aggregateReport?.reportId }}</span>
          } @else {
            -
          }
        </td>
      </tr>
    }
  </tbody>
  <tfoot>
    <tr>
      <td colspan="8"></td>
    </tr>
  </tfoot>
</table>
