import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Output,
  OnInit,
} from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { MenuItem } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [MenubarModule, CommonModule, RouterLink, RouterOutlet],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
})
export class NavigationComponent implements OnInit {
  @Output() logoutEvent = new EventEmitter<void>();
  name: string | undefined;
  items: MenuItem[] | undefined;
  isExpanded = false;

  constructor(private authService: MsalService) {}
  toggleSidebar(expanded: boolean) {
    this.isExpanded = expanded;
  }
  ngOnInit() {
    const account: AccountInfo | null =
      this.authService.instance.getActiveAccount();
    if (account) {
      this.name = account.name || 'Gast';
    }
  }

  logoutUser() {
    this.logoutEvent.emit();
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInside = (event.target as HTMLElement).closest('.sidebar');
    if (!clickedInside && this.isExpanded) {
      this.isExpanded = false;
    }
  }

  reloadPage() {
    window.location.href = '/order';
  }
}
