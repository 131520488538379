//Hygieneinspektionsstatus auf Auftragsebene
export enum HygieneInspectionStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  WaitingForLab = 'WAITING_FOR_LAB',
  InReview = 'IN_REVIEW',
  Completed = 'COMPLETED',
}

export const HygieneInspectionStatusLabels: {
  [key in HygieneInspectionStatus]: string;
} = {
  [HygieneInspectionStatus.Pending]: 'Ausstehend',
  [HygieneInspectionStatus.InProgress]: 'In Durchführung',
  [HygieneInspectionStatus.WaitingForLab]: 'Im Labor',
  [HygieneInspectionStatus.InReview]: 'Berichtsprüfung',
  [HygieneInspectionStatus.Completed]: 'Abgeschlossen',
};
