@if (env === 'dev') {
  <div class="dev-env-banner">
    <span>DEV Mode </span>
  </div>
}
@if (!loginDisplay && !isLoading) {
  <app-login (loginEvent)="loginPopup()" />
}
@if (isLoading) {
  <app-loading-animation />
}
@if (!isLoading && loginDisplay) {
  <app-navigation (logoutEvent)="logout()" />
  <div class="container">
    @if (!isIframe) {
      <router-outlet></router-outlet>
    }
  </div>
}

<app-toast />
