import { Component, OnInit } from '@angular/core';
import { TableComponent } from '../../shared/components/table/table.component';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { FilterComponent } from '../../shared/components/filter/filter.component';
import { NavigationComponent } from '../navigation/navigation.component';
import { AsyncPipe, CommonModule } from '@angular/common';
import { OrderService } from '../../core/order.service';
import { ErrorComponent } from '../../shared/components/error/error.component';
import { LoadingAnimationComponent } from '../../shared/components/loading-animation/loading-animation.component';
import { finalize } from 'rxjs';
import { FileService } from '../../shared/components/file-upload/file.service';
import { FileUploadComponent } from '../../shared/components/file-upload/file-upload.component';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { QueryParamsService } from '../../core/query-params.service';
import { LoggerService } from '../../core/logger.service';
import { OrderResponse } from '../../shared/models/order/order-response.model';

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [
    TableComponent,
    ButtonComponent,
    FilterComponent,
    NavigationComponent,
    AsyncPipe,
    CommonModule,
    ErrorComponent,
    LoadingAnimationComponent,
    FileUploadComponent,
  ],
  templateUrl: './order.component.html',
  styleUrl: './order.component.scss',
})
export class OrderComponent implements OnInit {
  orderList: OrderResponse[] = [];
  totalPages = 0;
  currentPage = 0;
  pageSize = 25;

  selectedFile?: File;
  fileError?: string;

  isLoading = true;
  error: Error | null = null;

  userGroups: string[] = [];
  filterValues?: Params;

  constructor(
    private orderService: OrderService,
    private fileService: FileService,
    private route: ActivatedRoute,
    private queryService: QueryParamsService,
    private loggerService: LoggerService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.handleQueryParams(params);
    });
  }

  updateAllOrders() {
    const http = this.queryService.getCurrentQueryParams();
    this.filterAllOrders(http);
  }

  filterAllOrders(filter: HttpParams) {
    this.isLoading = true;
    this.orderService
      .getPaginatedOrder(filter)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (data) => {
          this.loggerService.log('Fetch filtered order list completed');
          this.orderList = data.content;
          this.totalPages = data.totalPages;

          this.queryService.updateQueryParams(filter);
          this.isLoading = false;
        },
        error: (error: Error) => {
          this.loggerService.error('Error filtering order list');
          this.error = error;
        },
      });
  }

  downloadFile(): void {
    this.fileService.downloadExcelFile().subscribe({
      next: (data) => {
        this.handleFileDownload(data);
      },
    });
  }

  private handleQueryParams(params: Params): void {
    const httpParams = this.queryService.convertQueryParamsToHttpParams(params);

    if (Object.keys(params).length > 0) {
      this.loggerService.log('Retrieving filter params from url');
      this.filterValues = params;
      this.filterAllOrders(httpParams);
    } else {
      this.setInitialQueryParams();
    }
  }

  private setInitialQueryParams(): void {
    this.queryService.updateQueryParams(
      new HttpParams().set('page', this.currentPage).set('size', this.pageSize)
    );
  }

  private handleFileDownload(response: any): void {
    const contentDispositionHeader =
      response.headers.get('Content-Disposition') || '';

    const fileName = this.fileService.extractFilename(contentDispositionHeader);
    const blob = response.body;

    if (blob) {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(url);
    }
  }
}
