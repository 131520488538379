/**
 * Defines all different kinds of aggregate types.
 *
 * !!!
 * IMPORTANT: This enum is also defined in the following repositories: inspector-app, backoffice-backend => keep the versions synchronized
 * !!!
 */
export enum AggregateType {
  RLT_SUPPLY_AIR = 'RLT_SUPPLY_AIR',
  RLT_EXHAUST_AIR = 'RLT_EXHAUST_AIR',
  SHARED_AIR_INTAKE = 'SHARED_AIR_INTAKE',
  COMPACT_UNIT = 'COMPACT_UNIT',
  SPLIT_UNIT = 'SPLIT_UNIT',
  RECIRCULATION_COOLING_UNIT = 'RECIRCULATION_COOLING_UNIT',
  RECIRCULATION_HEATING_UNIT = 'RECIRCULATION_HEATING_UNIT',
  DOOR_AIR_CURTAIN = 'DOOR_AIR_CURTAIN',
  CEILING_CASSETTE = 'CEILING_CASSETTE',
}

export const AggregateTypeLabels: {
  [key in AggregateType]: string;
} = {
  [AggregateType.RLT_SUPPLY_AIR]: 'RLT-Zuluft',
  [AggregateType.RLT_EXHAUST_AIR]: 'RLT-Abluft',
  [AggregateType.SHARED_AIR_INTAKE]: 'Gem. AU',
  [AggregateType.COMPACT_UNIT]: 'Kompaktanlage',
  [AggregateType.SPLIT_UNIT]: 'Splitgerät',
  [AggregateType.RECIRCULATION_COOLING_UNIT]: 'Umluftkühlgerät',
  [AggregateType.RECIRCULATION_HEATING_UNIT]: 'Umluftheizgerät',
  [AggregateType.DOOR_AIR_CURTAIN]: 'Tür- / Torluftschleier',
  [AggregateType.CEILING_CASSETTE]: 'Deckenkassette',
};
